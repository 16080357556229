import { Injectable } from '@angular/core';
import { StabilityAIService } from './stability-ai.service';
import { OpenAIService } from './open-ai.service';
import { HuggingFaceAIService } from './hugging-face-ai.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageQuery } from './image-query';
import { map } from 'rxjs/operators';
import { ApiKeyService } from '../api-key.service';
import { ModelOption } from './image-generation-model';
import { CacheGenerationsService } from '../cache-generations-service';
import { GENERATED_IMAGES_KEY } from '../../../assets/constants/generation-constants';
import { ImageModels } from './image-model-enum';

@Injectable({ providedIn: 'root' })
export class ImageService {

  private _imgGenerations = new BehaviorSubject<GeneratedImages[]>([]);

  public generations = this._imgGenerations.asObservable();

  constructor(
    private readonly stabilityAIService: StabilityAIService,
    private readonly openAIService: OpenAIService,
    private readonly huggingFaceAIService: HuggingFaceAIService,
    private readonly apiKeyService: ApiKeyService,
    private readonly cacheGenerationsService: CacheGenerationsService<GeneratedImages>
  ) {
    void this.cacheGenerationsService.getGeneratedData(GENERATED_IMAGES_KEY).then(images => {
      this._imgGenerations.next(images || []);
    });
  }

  public generateImages(imageQuery: ImageQuery): Observable<GeneratedImages[]> {
    let generator: Observable<string[]>;
    switch (imageQuery.type) {
      case ImageModels.StabilityAIModel:
        generator = this.stabilityAIService.generateImage(imageQuery);
        break;
      case ImageModels.OpenAIModel:
        generator = this.openAIService.generateImage(imageQuery);
        break;
      default:
        generator = this.huggingFaceAIService.generateImage(imageQuery);
        break;
    }

    return generator.pipe(
      map(images => this.saveImages(imageQuery, images))
    );
  }

  public getAIModelOptionsBasedOnAPIKeys(): ModelOption[] {
    const models = [];
    if (this.apiKeyService.huggingFaceKey) {
      const hfModels = this.huggingFaceAIService.getAvailableModels();
      models.push(...hfModels);
    }
    if (this.apiKeyService.stabilityAIKey) {
      const stabilityAIModels = this.stabilityAIService.getAvailableModels();
      models.push(...stabilityAIModels);
    }
    if (this.apiKeyService.openAIKey) {
      const openAIModels = this.openAIService.getAvailableModels();
      models.push(...openAIModels);
    }
    return models;
  }

  private saveImages({version, prompt}: ImageQuery, images: string[]): GeneratedImages[] {
    const generations = this._imgGenerations.getValue();

    const newImages = images.map((base64): GeneratedImages => ({
      model: version,
      prompt,
      base64
    }));

    const allGenerations = [...generations, ...newImages];
    this._imgGenerations.next(allGenerations);
    void this.cacheGenerationsService.setGeneratedData(GENERATED_IMAGES_KEY, allGenerations);
    return newImages;
  }

  public async clearGenerations(): Promise<void> {
    this._imgGenerations.next([]);
    await this.cacheGenerationsService.clearGeneratedData(GENERATED_IMAGES_KEY);
  }
}

export interface GeneratedImages {
  model: string;
  prompt: string;
  base64: string;
}
