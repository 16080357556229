import { Observable } from 'rxjs';
import { FilterOption } from './image-generation-filters';
import { ImageQuery } from './image-query';
import { ImageModels } from './image-model-enum';

export abstract class ImageGenerationModel {
  protected models: ModelOption[] = [];

  constructor() { }

  public abstract getAvailableModels(): ModelOption[];

  public abstract generateImage(imageQuery: ImageQuery): Observable<string[]>;
}

export interface ModelOption {
  type: ImageModels;
  label: string;
  value: string;
  generationCost: string;
  sampleLimit: number;
  filters: Record<string, FilterOption[]>;
}
