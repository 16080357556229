/**
 * The StorageKey used to store generated images.
 * @constant
 */
export const GENERATED_IMAGES_KEY = 'generatedImages';

/**
 * The StorageKey used to store generated descriptions.
 * @constant
 */
export const GENERATED_DESCRIPTIONS_KEY = 'generatedDescriptions';

/**
 * The StorageKey used to store available Rytr languages.
 * @constant
 */
export const RYTR_LANGUAGES_KEY = 'rytrLanguages';


/**
 * The StorageKey used to store available Rytr Tones.
 * @constant
 */
export const RYTR_TONES_KEY = 'rytrTones';


/**
 * The default no of image variants that could be generated using the AI models
 * @constant
 */
export const DEFAULT_IMAGE_VARIANTS_SIZE = 5;



/**
 * The default no of description variants that could be generated using the AI models
 * @constant
 */
export const DEFAULT_DESCRIPTION_VARIANTS_SIZE = 3;

