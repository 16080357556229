import { Component, Input } from '@angular/core';
import { GeneratedImages } from 'src/model/generation/image/image.service';

@Component({
  selector: 'app-image-preview',
  templateUrl: 'image-preview.component.html',
  styleUrls: ['image-preview.component.scss']
})
export class ImagePreviewComponent {
  @Input() public prompt = '';

  @Input() public images: GeneratedImages[] = [];

  public downloadImage(imageStr: string): void {
    const downloadAnchor = document.createElement('a');
    downloadAnchor.href = imageStr;
    downloadAnchor.download = 'generated_image.png';
    downloadAnchor.click();
  }
}
