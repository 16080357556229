import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss']
})

export class CardComponent {
  @Input() public items: { date: string; text: string }[] = [];

  constructor(private readonly toastCtrl: ToastController) { }

  public async copyToClipboard(textToCopy: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(textToCopy);

      const successMessage = await this.toastCtrl.create({
        message: 'Copied to clipboard successfully',
        color: 'success',
        position: 'bottom',
        duration: 3000
      });
      await successMessage.present();
    } catch (err) {
      const errorMessage = await this.toastCtrl.create({
        message: 'Failed to copy text. Try again.',
        color: 'danger',
        position: 'bottom',
        duration: 3000
      });
      await errorMessage.present();
    }
  }

}
