/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../api-key.service';
import { StabilityAIImageQuery } from './image-query';
import { catchError, map } from 'rxjs/operators';
import { StabilityAIEngines } from '../stability-ai-engines-enum';
import { ImageGenerationModel, ModelOption } from './image-generation-model';
import { Observable, throwError } from 'rxjs';
import { ImageModels } from './image-model-enum';
import { DEFAULT_IMAGE_VARIANTS_SIZE } from 'src/assets/constants/generation-constants';

@Injectable({ providedIn: 'root' })
export class StabilityAIService extends ImageGenerationModel {
  protected readonly models: ModelOption[] = [
    { type: ImageModels.StabilityAIModel, label: 'Stability AI XL1', value: 'XL1', generationCost: '$0.002', sampleLimit: DEFAULT_IMAGE_VARIANTS_SIZE, filters: {} },
    { type: ImageModels.StabilityAIModel, label: 'Stability AI 1.6', value: '1.6', generationCost: '$0.003', sampleLimit: DEFAULT_IMAGE_VARIANTS_SIZE, filters: {} }
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly apiKeyService: ApiKeyService
  ) {
    super();
  }

  public generateImage(imageQuery: StabilityAIImageQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.apiKeyService.stabilityAIKey}`,
    });

    let engine = StabilityAIEngines.StabilityAIEngineXL1;
    let imageSize = 1024;
    switch (imageQuery.version) {
      case '1.6':
        engine = StabilityAIEngines.StabilityAIEngine16;
        imageSize = 1024;
        break;
    }

    return this.http.post<{ artifacts: { base64: string }[] }>(
      `https://api.stability.ai/v1/generation/${engine}/text-to-image`,
      JSON.stringify({
        text_prompts: [
          {
            text: imageQuery.prompt,
          },
        ],
        steps: 30,
        width: imageSize,
        height: imageSize,
        seed: 0,
        cfg_scale: 5,
        samples: parseInt(imageQuery.sampleSize, 10)
      }),
      { headers },
    ).pipe(
      map((res) => res.artifacts.map(artifact => 'data:image/png;base64,' + artifact.base64)),
      catchError(err => err.status === 429
        ? throwError('Please check if you have enough credits to perform this action')
        : throwError(err)
      )
    );
  }

  public getAvailableModels(): ModelOption[] {
    return this.models;
  }
}
