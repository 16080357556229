/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../../api-key.service';
import { environment } from 'src/environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';
import { RtyrApiOption } from './rytr-api-option';
import { Observable, combineLatest, from, of } from 'rxjs';
import { Storage } from '@ionic/storage';
import { DEFAULT_DESCRIPTION_VARIANTS_SIZE, RYTR_LANGUAGES_KEY, RYTR_TONES_KEY } from 'src/assets/constants/generation-constants';
import { DescriptionModels } from '../description-model-enum';
import { DropdownOption } from 'src/model/dropdown-option';
import { RytrQuery } from './rytr-query';
import { DescriptionModelOptions } from '../description-model-options';
import { DescriptionModelConfig } from '../description-model-config';

@Injectable({ providedIn: 'root' })
export class RytrDescriptionService {
  private readonly useCaseId = '605832f78c0a4a000c69c960'; // Product description

  public readonly config: DescriptionModelConfig = {
    label: 'Rytr',
    value: DescriptionModels.RytrModel,
    maxSampleSize: DEFAULT_DESCRIPTION_VARIANTS_SIZE,
  };

  public readonly englishLanguageId = '607adac76f8fe5000c1e636d'; // English as default

  constructor(private readonly http: HttpClient,
    private apiKeyService: ApiKeyService,
    private storage: Storage
  ) { }

  public getModelOptions(): Observable<DescriptionModelOptions[]> {
    return combineLatest([
      this.getLanguagesList(),
      this.getTonesList(),
      of({
        language: this.englishLanguageId,
        tone: ''
      })
    ]).pipe(map(([languages, tones, defaults]) => [
      { name: 'language', options: languages, value: defaults.language || languages[0]?.value },
      { name: 'tone', options: tones, value: defaults.tone || tones[0]?.value }
    ]));
  }

  public getLanguagesList(): Observable<DropdownOption[]> {
    return from(this.getLanguagesFromStorage()).pipe(
      switchMap(savedLanguages => {
        if (savedLanguages) {
          return of(savedLanguages);
        }

        return this.getLanguagesFromAPI();
      })
    );
  }

  public getTonesList(): Observable<DropdownOption[]> {
    return from(this.getTonesFromStorage()).pipe(
      switchMap(savedTones => {
        if (savedTones) {
          return of(savedTones);
        }

        return this.getTonesFromAPI();
      })
    );
  }

  public generateProductDescription(query: RytrQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authentication: `Bearer ${this.apiKeyService.rytrKey}`,
    });

    return this.http.post<{ data: string[] }>(
      environment.rytrAPIUrl + '/ryte',
      JSON.stringify({
        languageId: query.language,
        toneId: query.tone,
        useCaseId: this.useCaseId,
        format: 'text',
        creativityLevel: 'default',
        translate: 'standard',
        variations: parseInt(query.sampleSize, 10),
        userId: 1,
        inputContexts: {
          PRODUCT_NAME_LABEL: query.name,
          ABOUT_PRODUCT_LABEL: query.description
        }
      }),
      { headers }
    ).pipe(
      map(res => res.data)
    );
  }

  private getLanguagesFromStorage(): Promise<DropdownOption[]> {
    return this.storage.get(RYTR_LANGUAGES_KEY);
  }

  private getLanguagesFromAPI(): Observable<DropdownOption[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authentication: `Bearer ${this.apiKeyService.rytrKey}`,
    });

    return this.http.get<{ data: RtyrApiOption[] }>(
      environment.rytrAPIUrl + '/languages',
      { headers },
    ).pipe(
      map(res => res.data.map((value): DropdownOption => ({ label: value.name, value: value._id }))),
      tap(languageList => this.storage.set(RYTR_LANGUAGES_KEY, languageList))
    );
  }

  private getTonesFromStorage(): Promise<DropdownOption[]> {
    return this.storage.get(RYTR_TONES_KEY);
  }

  private getTonesFromAPI(): Observable<DropdownOption[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authentication: `Bearer ${this.apiKeyService.rytrKey}`,
    });

    return this.http.get<{ data: RtyrApiOption[] }>(
      environment.rytrAPIUrl + '/tones',
      { headers },
    ).pipe(
      map(res => res.data.map((value): DropdownOption => ({ label: value.name, value: value._id }))),
      tap(tonesList => this.storage.set(RYTR_TONES_KEY, tonesList))
    );
  }

  public async resetRytrCache(): Promise<void> {
    await this.storage.remove(RYTR_TONES_KEY);
    await this.storage.remove(RYTR_LANGUAGES_KEY);
  }

}
