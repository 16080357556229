import { AIModelPageBase } from 'src/app/ai-model-page-base';

export class ImageGenerationFilters extends AIModelPageBase {

  public readonly tablewareOptions: FilterOption[] = [
    { label: 'Plate', value: 'plate' },
    { label: 'Bowl', value: 'bowl' },
    { label: 'Cup', value: 'cup' },
  ];

  public readonly tablewareColorOptions: FilterOption[] = [
    { label: 'White', value: 'white' },
    { label: 'Black', value: 'black' },
    { label: 'Gray', value: 'gray' },
  ];

  public readonly backgroundColorOptions: FilterOption[] = [
    { label: 'White', value: 'white' },
    // Currently the AI models do not support transparant backgrounds
    // { label: 'Transparent', value: 'transparent' },
    { label: 'Rich', value: 'vibrant' },
  ];

}


export interface FilterOption {
  label: string;
  value: string;
}
