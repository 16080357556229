import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Directive()
export class AIModelPageBase {

  private pageKey = '';

  public form = new FormGroup({});

  constructor(pageKey: string) {
    this.pageKey = pageKey;
  }

  public async ionViewWillEnter(): Promise<void> {
    const formState = this.getFromLocalStorage();
    if(formState){
      try {
        const formStateJson = JSON.parse(formState);
        this.form.patchValue(formStateJson);
      } catch { }
    }
  }

  public ionViewWillLeave(): void {
    this.saveToLocalStorage(JSON.stringify(this.form.value));
  }

  private saveToLocalStorage(value: string): void {
    localStorage.setItem(this.pageKey, value);
  }

  private getFromLocalStorage(): string | null {
    return localStorage.getItem(this.pageKey);
  }
}
