/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../api-key.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';
import { ImageGenerationModel, ModelOption } from './image-generation-model';
import { Observable } from 'rxjs';
import { HuggingFaceImageQuery } from './image-query';
import { ImageModels } from './image-model-enum';

@Injectable({ providedIn: 'root' })
export class HuggingFaceAIService extends ImageGenerationModel {

  protected readonly models: ModelOption[] = [
    { type: ImageModels.HuggingFaceModel, label: 'HuggingFace AI', value: 'HF', generationCost: '', sampleLimit: 1, filters: {} },
  ];

  constructor(
    private readonly http: HttpClient,
    private readonly apiKeyService: ApiKeyService
  ) {
    super();
  }

  public generateImage({prompt}: HuggingFaceImageQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.apiKeyService.huggingFaceKey}`,
    });

    const response = this.http.post(
      environment.huggingFaceAPIUrl,
      JSON.stringify({
        inputs: prompt
      }),
      { headers, responseType: 'blob' }
    );

    return response.pipe(
      switchMap(async res => [await this.blobToBase64(res)])
    );
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve('data:image/png;base64,' + (reader.result as string).split(',').slice(1).join(','));
      reader.onerror = reject;
      if(blob.type === 'application/json'){
        reject(await blob.text());
      }
      reader.readAsDataURL(blob);
    });
  }

  public getAvailableModels(): ModelOption[] {
    return this.models;
  }
}
