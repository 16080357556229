/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../../api-key.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DescriptionModels } from '../description-model-enum';
import { WriteSonicQuery } from './write-sonic-query';
import { DropdownOption } from 'src/model/dropdown-option';
import { DescriptionModelOptions } from '../description-model-options';
import { DescriptionModelConfig } from '../description-model-config';
import { DEFAULT_DESCRIPTION_VARIANTS_SIZE } from 'src/assets/constants/generation-constants';

@Injectable({ providedIn: 'root' })
export class WriteSonicDescriptionService {
  public config: DescriptionModelConfig = {
    label: 'WriteSonic',
    value: DescriptionModels.WriteSonicModel,
    maxSampleSize: DEFAULT_DESCRIPTION_VARIANTS_SIZE,
  };

  constructor(private readonly http: HttpClient,
    private apiKeyService: ApiKeyService
  ) { }

  private get supportedLanguages(): DropdownOption[] {
    return this.sortAlphabetically([
      { label: 'English', value: 'en' },
      { label: 'Dutch', value: 'nl' },
      { label: 'French', value: 'fr' },
      { label: 'German', value: 'de' },
      { label: 'Italian', value: 'it' },
      { label: 'Polish', value: 'pl' },
      { label: 'Spanish', value: 'es' },
      { label: 'Portuguese', value: 'pt-pt' },
      { label: 'Brazilian Portuguese', value: 'pt-br' },
      { label: 'Russian', value: 'ru' },
      { label: 'Japanese', value: 'ja' },
      { label: 'Chinese', value: 'zh' },
      { label: 'Bulgarian', value: 'bg' },
      { label: 'Czech', value: 'cs' },
      { label: 'Danish', value: 'da' },
      { label: 'Greek', value: 'el' },
      { label: 'Hungarian', value: 'hu' },
      { label: 'Lithuanian', value: 'lt' },
      { label: 'Latvian', value: 'lv' },
      { label: 'Romanian', value: 'ro' },
      { label: 'Slovak', value: 'sk' },
      { label: 'Slovenian', value: 'sl' },
      { label: 'Swedish', value: 'sv' },
      { label: 'Finnish', value: 'fi' },
      { label: 'Estonian', value: 'et' }
    ]);
  }

  public get supportedTones(): DropdownOption[] {
    const tones =  [
      'Excited',
      'Professional',
      'Funny',
      'Encouraging',
      'Dramatic',
      'Witty',
      'Sarcastic',
      'Engaging',
      'Creative'
    ].map(val => ({ label: val, value: val }));
    return this.sortAlphabetically(tones);
  }

  public getModelOptions(): Observable<DescriptionModelOptions[]> {
    return of([
      { name: 'language', options: this.supportedLanguages, value: this.supportedLanguages[0]?.value },
      { name: 'tone', options: this.supportedTones, value: this.supportedTones[0]?.value }
    ]);
  }

  public generateProductDescription(writeSonicQuery: WriteSonicQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      accept: 'application/json',
      'content-type': 'application/json',
      'X-API-KEY': `${this.apiKeyService.writeSonicKey}`
    });

    return this.http.post<{ text: string }[]>(
      `https://api.writesonic.com/v2/business/content/product-descriptions?engine=premium&language=${writeSonicQuery.language}&num_copies=${writeSonicQuery.sampleSize}`,
      JSON.stringify({
        tone_of_voice: writeSonicQuery.tone,
        product_name: writeSonicQuery.name,
        product_characteristics: writeSonicQuery.description
      }),
      { headers }
    ).pipe(
      map(res => res.map(desc => desc.text))
    );
  }


  public sortAlphabetically(options: DropdownOption[]):  DropdownOption[]{
    options.sort((a: DropdownOption, b: DropdownOption) => {
      const first = a.label.toLowerCase();
      const second = b.label.toLowerCase();

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });
    return options;
  }
}
