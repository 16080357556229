import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApticMessageController } from '@aptic/core';
import { ApiKeyService } from 'src/model/generation/api-key.service';
import { RytrDescriptionService } from 'src/model/generation/description/rytr/rytr-description.service';

@Component({
  selector: 'app-settings',
  templateUrl: 'settings.page.html',
  styleUrls: ['settings.page.scss']
})

export class SettingsPage {
  public inputType: 'text' | 'password' = this.getDefaultInputType();

  public form = new FormGroup({
    storageType: new FormControl(this.apiKeyService.storageType === 'local'),
    huggingFaceKey: new FormControl(this.apiKeyService.huggingFaceKey),
    stabilityAIKey: new FormControl(this.apiKeyService.stabilityAIKey),
    openAIKey: new FormControl(this.apiKeyService.openAIKey),
    rytrKey: new FormControl(this.apiKeyService.rytrKey),
    copyAIKey: new FormControl(this.apiKeyService.copyAIKey),
    writeSonicKey: new FormControl(this.apiKeyService.writeSonicKey)
  });

  constructor(
    private readonly apiKeyService: ApiKeyService,
    private readonly rytrDescriptionService: RytrDescriptionService,
    private readonly msgCtrl: ApticMessageController
  ) { }

  public async saveSettings(): Promise<void> {
    const formValues = this.form.value;

    const storageType = formValues.storageType ? 'local' : 'session';

    this.apiKeyService.update(storageType, {
      huggingFaceKey: formValues.huggingFaceKey || '',
      stabilityAIKey: formValues.stabilityAIKey || '',
      openAIKey: formValues.openAIKey || '',
      rytrKey: formValues.rytrKey || '',
      copyAIKey: formValues.copyAIKey || '',
      writeSonicKey: formValues.writeSonicKey || ''
    });
    this.inputType = 'password';
    await this.msgCtrl.showSuccess({
      message: 'Saved Successfully'
    });
  }

  public async clearSettings(): Promise<void> {
    this.apiKeyService.removeFromStorage();
    this.form.reset();
    this.inputType = 'text';
    await this.msgCtrl.showInformation({
      message: 'Cleared saved API keys'
    });
  }

  public async clearRytrSettings(): Promise<void> {
    await this.rytrDescriptionService.resetRytrCache();
  }

  public toggleInputType(): void {
    this.inputType = this.inputType === 'text' ? 'password' : 'text';
  }

  private getDefaultInputType(): 'text' | 'password' {
    if (!this.isAPIKeysPresent) {
      return 'text';
    }
    return 'password';
  }

  public get isAPIKeysPresent(): boolean {
    return this.apiKeyService.hasImageAPIKeys() || this.apiKeyService.hasDescriptionAPIKeys();
  }
}
