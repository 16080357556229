import { Injectable } from '@angular/core';
import { RytrDescriptionService } from './rytr/rytr-description.service';
import { BehaviorSubject, EMPTY, Observable, combineLatest, of } from 'rxjs';
import { RytrQuery } from './rytr/rytr-query';
import { map } from 'rxjs/operators';
import { CacheGenerationsService } from '../cache-generations-service';
import { GENERATED_DESCRIPTIONS_KEY } from '../../../assets/constants/generation-constants';
import { CopyAIQuery } from './copy-ai/copy-ai-query';
import { CopyAIDescriptionService } from './copy-ai/copy-ai-description.service';
import { ApiKeyService } from '../api-key.service';
import { WriteSonicDescriptionService } from './write-sonic/write-sonic-description.service';
import { WriteSonicQuery } from './write-sonic/write-sonic-query';
import { DescriptionModels } from './description-model-enum';
import { DescriptionModelOptions } from './description-model-options';
import { DescriptionModelConfig } from './description-model-config';
import { DescriptionQuery } from './description-model-query';

@Injectable({ providedIn: 'root' })
export class DescriptionService {

  private _generations = new BehaviorSubject<GeneratedDescription[]>([]);

  public generations$ = this._generations.asObservable();

  constructor(
    protected rytrService: RytrDescriptionService,
    protected copyAIService: CopyAIDescriptionService,
    protected apiKeyService: ApiKeyService,
    protected writeSonicDescriptionService: WriteSonicDescriptionService,
    private readonly cacheGenerationsService: CacheGenerationsService<GeneratedDescription>) {

    void this.cacheGenerationsService.getGeneratedData(GENERATED_DESCRIPTIONS_KEY).then(descriptions => {
      this._generations.next(descriptions || []);
    });
  }

  public getModels(): DescriptionModelConfig[] {
    const models = [];
    if (this.apiKeyService.rytrKey) {
      models.push(this.rytrService.config);
    }
    if (this.apiKeyService.copyAIKey) {
      models.push(this.copyAIService.config);
    }
    if (this.apiKeyService.writeSonicKey) {
      models.push(this.writeSonicDescriptionService.config);
    }
    return models;
  }

  public getModelOptions(model: DescriptionModels): Observable<DescriptionModelOptions[]> {
    if (model === DescriptionModels.RytrModel) {
      return this.rytrService.getModelOptions();
    }
    if (model === DescriptionModels.CopyAIModel) {
      return this.copyAIService.getModelOptions();
    }
    if (model === DescriptionModels.WriteSonicModel) {
      return this.writeSonicDescriptionService.getModelOptions();
    }
    return of([]);
  }

  public generateDescription(model: string, values: DescriptionQuery): Observable<GeneratedDescription[]> {
    if (model === DescriptionModels.RytrModel) {
      return this.generateDescriptionUsingRytr(values as RytrQuery);
    }
    if (model === DescriptionModels.CopyAIModel) {
      return this.generateDescriptionsUsingCopyAI(values as CopyAIQuery);
    }
    if (model === DescriptionModels.WriteSonicModel) {
      return this.generateDescriptionsUsingWriteSonic(values as WriteSonicQuery);
    }

    return EMPTY;
  }

  public generateDescriptionsUsingWriteSonic(query: WriteSonicQuery): Observable<GeneratedDescription[]> {
    return this.writeSonicDescriptionService.generateProductDescription(query).pipe(
      map(descriptions => this.updateExistingGenerationsWithLatestGeneration(descriptions, DescriptionModels.WriteSonicModel))
    );
  }

  public generateDescriptionsUsingCopyAI(query: CopyAIQuery): Observable<GeneratedDescription[]> {
    return this.copyAIService.generateProductDescription(query).pipe(
      map(descriptions => this.updateExistingGenerationsWithLatestGeneration(descriptions, DescriptionModels.CopyAIModel))
    );
  }

  public generateDescriptionUsingRytr(query: RytrQuery): Observable<GeneratedDescription[]> {
    return this.rytrService.generateProductDescription(query).pipe(
      map(descriptions => this.updateExistingGenerationsWithLatestGeneration(descriptions, DescriptionModels.RytrModel))
    );
  }

  public updateExistingGenerationsWithLatestGeneration(newDescriptions: string[], descriptionModel: DescriptionModels): GeneratedDescription[] {
    const newDescription = newDescriptions.map(description => ({
      text: description,
      date: new Date().toString(),
      models: descriptionModel
    }));
    const updatedData: GeneratedDescription[] = [
      ...newDescription,
      ...this._generations.getValue(),
    ];

    this._generations.next(updatedData);
    void this.cacheGenerationsService.setGeneratedData(GENERATED_DESCRIPTIONS_KEY, updatedData);
    return newDescription;
  }

  public async clearGenerations(): Promise<void> {
    this._generations.next([]);
    await this.cacheGenerationsService.clearGeneratedData(GENERATED_DESCRIPTIONS_KEY);
  }
}

export interface GeneratedDescription {
  text: string;
  date: string;
  models: DescriptionModels;
}
