import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { SettingsPage } from './settings/settings.page';
import { ImagesPage } from './images/images.page';
import { DescriptionsPage } from './descriptions/descriptions.page';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'images'
  },
  {
    path: 'images',
    component: ImagesPage
  },
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'descriptions',
    component: DescriptionsPage
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

