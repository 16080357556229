import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  public selectedTab = this.router.url;

  @Input() public title = '';

  @Input() public isBackButtonRequired = false;

  public actionIcon = this.router.url !== '/settings' ? 'settings-outline' : 'home';

  constructor(private readonly router: Router) {
  }

  public ngOnInit(): void {
    this.subscriptions.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectedTab = event.urlAfterRedirects;
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async navigate(): Promise<void> {
    if (this.router.url !== '/settings') {
      await this.router.navigateByUrl('/settings');
    } else {
      await this.router.navigateByUrl('/home');
    }
  }

  public async onTabChange(value: string): Promise<void> {
    await this.router.navigateByUrl(value);
  }
}
