import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { CardComponent } from './card/card.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule
  ],
  declarations: [HeaderComponent, DropdownComponent, ImagePreviewComponent, CardComponent],
  exports: [HeaderComponent, DropdownComponent, ImagePreviewComponent, CardComponent]
})
export class ComponentsModule {

}
