import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({providedIn: 'root'})
export class CacheGenerationsService<T> {
  constructor(private readonly storage: Storage) { }

  public async getGeneratedData(key: string): Promise<T[]> {
    try {
      const dataList: T[] = JSON.parse(await this.storage.get(key));
      return dataList || [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  public async clearGeneratedData(key: string): Promise<void> {
    await this.storage.set(key, JSON.stringify([]));
  }

  public async setGeneratedData(key: string, value: T[]): Promise<void> {
    await this.storage.set(key, JSON.stringify(value));
  }
}
