import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ApiKeyService {

  private readonly API_KEY = 'apikey';

  public huggingFaceKey = '';
  public stabilityAIKey = '';
  public openAIKey = '';
  public rytrKey = '';
  public copyAIKey = '';
  public writeSonicKey = '';

  public storageType: 'session' | 'local' = 'local';

  constructor() {
    let apiData = sessionStorage.getItem(this.API_KEY);
    if (apiData) {
      this.storageType = 'session';
      this.parseJson(apiData);
      return;
    }

    apiData = localStorage.getItem(this.API_KEY) || '';
    this.parseJson(apiData);
  }

  private parseJson(data: string): void {
    if (!data) {
      return;
    }
    try {
      const json = JSON.parse(data);

      this.huggingFaceKey = json.huggingFaceKey || '';
      this.stabilityAIKey = json.stabilityAIKey || '';
      this.openAIKey = json.openAIKey || '';
      this.rytrKey = json.rytrKey || '';
      this.copyAIKey = json.copyAIKey || '';
      this.writeSonicKey = json.writeSonicKey || '';

    } catch {
      console.log('Error parsing storage data');
    }
  }

  public update(storageType: typeof this.storageType, {
    huggingFaceKey,
    stabilityAIKey,
    openAIKey,
    rytrKey,
    copyAIKey,
    writeSonicKey
  }: Record<string, string>): void {
    this.removeFromStorage();

    this.storageType = storageType;

    this.huggingFaceKey = huggingFaceKey || '';
    this.stabilityAIKey = stabilityAIKey || '';
    this.openAIKey = openAIKey || '';
    this.rytrKey = rytrKey || '';
    this.copyAIKey = copyAIKey || '';
    this.writeSonicKey = writeSonicKey || '';

    this.saveToStorage();
  }

  private saveToStorage(): void {
    const storage = this.storageType === 'local' ? localStorage : sessionStorage;

    storage.setItem(
      this.API_KEY,
      JSON.stringify({
        huggingFaceKey: this.huggingFaceKey,
        stabilityAIKey: this.stabilityAIKey,
        openAIKey: this.openAIKey,
        rytrKey: this.rytrKey,
        copyAIKey: this.copyAIKey,
        writeSonicKey: this.writeSonicKey
      })
    );
  }

  public removeFromStorage(): void {
    localStorage.removeItem(this.API_KEY);
    sessionStorage.removeItem(this.API_KEY);
    // Reset
    this.huggingFaceKey = '';
    this.stabilityAIKey = '';
    this.openAIKey = '';
    this.rytrKey = '';
    this.copyAIKey = '';
    this.writeSonicKey = '';
    this.storageType = 'local';
  }

  public hasImageAPIKeys(): boolean {
    return !!this.huggingFaceKey || !!this.stabilityAIKey || !!this.openAIKey;
  }

  public hasDescriptionAPIKeys(): boolean {
    return !!this.rytrKey || !!this.copyAIKey || !!this.writeSonicKey;
  }
}
