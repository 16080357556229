// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {AppConfig} from './app-config';

export const environment: AppConfig = {
  apiUrl: '', // TODO: Add the API Url
  appVersion: '1.0.0',
  production: false,
  appName: 'ISS Product Image Generation',
  iosTeamId: '7ZNPS3NT55',
  iosAppBundleId: 'nl.aptic.starter',
  androidAppBundleId: 'nl.aptic.starter',
  huggingFaceAPIUrl:'https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-xl-base-1.0',
  huggingFaceAPIKey: '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',
  openAIAPIUrl: 'https://api.openai.com/v1/images/generations',
  openAIAPIKey: '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',
  stabilityAIAPIUrl: 'https://api.stability.ai/v1/generation/${engine}/text-to-image',
  stabilityAIAPIKey: 'sk-lkwopTfuAdYV7qiMFX1uJwHFvlkj5epdlHcl5jBY0FLzQBpG',
  rytrAPIUrl: 'https://api.rytr.me/v1',
  copyAIAPIUrl: 'https://api.copy.ai/api/workflow/WCFG-285203f9-34c0-4782-8ae7-e2f0203a3085/run',
  writeSonicAPIUrl: 'https://api.writesonic.com/v2/business/content/product-descriptions?engine=premium&language=${language}&num_copies={sampleSize}'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
