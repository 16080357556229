/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../api-key.service';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ImageGenerationModel, ModelOption } from './image-generation-model';
import { Observable, throwError } from 'rxjs';
import { OpenAIImageQuery } from './image-query';
import { ImageModels } from './image-model-enum';
import { DEFAULT_IMAGE_VARIANTS_SIZE } from 'src/assets/constants/generation-constants';

@Injectable({ providedIn: 'root' })
export class OpenAIService extends ImageGenerationModel {

  private readonly openAI3FIlters = {
    style: [
      { label: 'Vivid', value: 'vivid' },
      { label: 'Natural', value: 'natural' }
    ]
  };

  protected readonly models: ModelOption[] = [
    {
      type: ImageModels.OpenAIModel, label: 'Open AI 2', value: 'openAI2', generationCost: '$0.020', sampleLimit: DEFAULT_IMAGE_VARIANTS_SIZE, filters: {}
    },
    {
      type: ImageModels.OpenAIModel, label: 'Open AI 3', value: 'openAI3', generationCost: '$0.040', sampleLimit: 1, filters: this.openAI3FIlters
    },
    {
      type: ImageModels.OpenAIModel, label: 'Open AI 3 HD', value: 'openAI3Hd', generationCost: '$0.080', sampleLimit: 1, filters: this.openAI3FIlters
    }
  ];


  constructor(
    private readonly http: HttpClient,
    private readonly apiKeyService: ApiKeyService
  ) {
    super();
  }

  public generateImage({ prompt, sampleSize, version = 'dall-e-2', style }: OpenAIImageQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${this.apiKeyService.openAIKey}`,
    });

    return this.http.post<{ data: { b64_json: string }[] }>(
      environment.openAIAPIUrl,
      JSON.stringify({
        model: version === 'openAI3Hd' || version === 'openAI3' ? 'dall-e-3' : 'dall-e-2',
        prompt,
        n: parseInt(sampleSize, 10),
        size: '1024x1024',
        response_format: 'b64_json',
        quality: version === 'openAI3Hd' ? 'hd' : 'standard',
        style
      }),
      { headers },
    ).pipe(
      map(response => response.data.map((value) => 'data:image/png;base64,' + value.b64_json)),
      catchError(err => err.status === 429
        ? throwError('Please check if you have enough credits to perform this action')
        : throwError(err)
      )
    );
  }

  public getAvailableModels(): ModelOption[] {
    return this.models;
  }

}
