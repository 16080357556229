import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FilterOption } from 'src/model/generation/image/image-generation-filters';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() public label = '';

  @Input() public options: FilterOption[] = [];

  @Input() public selectedOption = '';

  @Input() public disabled = false;

  @Output() public onSelect = new EventEmitter<string>();

  public openEvent: MouseEvent | null = null;

  constructor() { }

  public selectOption(evt: Event): void {
    const value = (evt as CustomEvent).detail.value;
    this.onSelect.emit(value);
    this.openEvent = null;
  }

  public get uniqueDropdownID(): string {
    return this.label.toLowerCase().replace(/\s+/g, '-');
  }

  public get selectedOptionLabel(): string {
    return this.options.find(option => option.value === this.selectedOption)?.label || '';
  }

  public openPopover(event: MouseEvent): void {
    this.openEvent = event;
  }

}
