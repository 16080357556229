/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyService } from '../../api-key.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DescriptionModels } from '../description-model-enum';
import { CopyAIQuery } from './copy-ai-query';
import { DescriptionModelConfig } from '../description-model-config';
import { DescriptionModelOptions } from '../description-model-options';

@Injectable({ providedIn: 'root' })
export class CopyAIDescriptionService {
  public config: DescriptionModelConfig = {
    label: 'Copy AI',
    value: DescriptionModels.CopyAIModel,
    maxSampleSize: 1,
  };

  constructor(private readonly http: HttpClient,
    private apiKeyService: ApiKeyService
  ) { }

  public getModelOptions(): Observable<DescriptionModelOptions[]> {
    return of([]);
  }

  public generateProductDescription(query: CopyAIQuery): Observable<string[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-copy-ai-api-key': `${this.apiKeyService.copyAIKey}`
    });

    return this.http.post<{ data: string[] }>(
      environment.copyAIAPIUrl,
      JSON.stringify({
        startVariables: { product_name: query.name, product_description: query.description }, metadata: { api: true }
      }),
      { headers, withCredentials: true }
    ).pipe(
      map(res => res.data)
    );
  }

}
