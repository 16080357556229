/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { ApticMessageController } from '@aptic/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { GeneratedImages, ImageService } from 'src/model/generation/image/image.service';
import { ApiKeyService } from 'src/model/generation/api-key.service';
import { Router } from '@angular/router';
import { ModelOption } from 'src/model/generation/image/image-generation-model';
import { FilterOption, ImageGenerationFilters } from 'src/model/generation/image/image-generation-filters';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-images-page',
  templateUrl: 'images.page.html',
  styleUrls: ['images.page.scss'],
})
export class ImagesPage extends ImageGenerationFilters {
  public availableModels: ModelOption[] = [];

  public form = this.fb.group({
    prompt: ['', Validators.required],
    description: ['', Validators.required],
    tableware: 'plate',
    tablewareColor: 'white',
    cutlery: true,
    bgColor: 'white',
    aiVersion: '',
    sampleSize: '1',
  });

  public generatedImages: GeneratedImages[] = [];
  public allGeneratedImages = this.imageService.generations.pipe(
    map(([...images]) => {
      // Show images in reverse cronological order
      images.reverse();

      return images;
    })
  );
  public loading = false;
  public selectedImageIndex = -1;
  public errorText = '';

  constructor(
    protected readonly msgCtrl: ApticMessageController,
    protected readonly fb: UntypedFormBuilder,
    protected readonly imageService: ImageService,
    protected readonly apiKeyService: ApiKeyService,
    protected readonly router: Router) {
    super('images-page');
  }

  public async ionViewWillEnter(): Promise<void> {
    await super.ionViewWillEnter();

    if (!this.apiKeyService.hasImageAPIKeys()) {
      await this.msgCtrl.showInterruptingInformation(
        {
          header: 'Missing API Keys',
          message: 'Please fill in the API keys to continue with the image generation',
          buttons: [
            {
              text: 'Go to settings',
              handler: async () => {
                await this.router.navigateByUrl('/settings');
              }
            },
          ],
          backdropDismiss: false
        },
      );
    }
    this.availableModels = this.imageService.getAIModelOptionsBasedOnAPIKeys();
    if (this.form.value.aiVersion === '' && this.availableModels[0]) {
      this.setFormControlValue('aiVersion', this.availableModels[0].value);
    }
  }

  public get modelGenerationCost(): string {
    if (this.selectedModel?.value === 'HF') {
      return '';
    }
    return this.selectedModel?.generationCost || '';
  }

  public get selectedModel(): ModelOption | undefined {
    return this.availableModels.find(model => model.value === this.form.value.aiVersion);
  }

  public get modelSampleSize(): FilterOption[] {
    const sampleSize: FilterOption[] = [];
    if (this.selectedModel) {
      for (let i = 1; i <= this.selectedModel.sampleLimit; i++) {
        sampleSize.push({ label: (i).toString(), value: i.toString() });
      }
    }
    return sampleSize;
  }

  public async generateImage(): Promise<void> {
    if (!this.form.valid || !this.selectedModel) {
      this.errorText = 'Product name and details are required to generate images.';
      return;
    }
    this.errorText = '';
    this.loading = true;
    this.generatedImages = [];

    const value = this.form.value;

    const prompt = [
      value.prompt, value.description,
      'centered, zoomed out', 'white background',
      `served on a ${value.tablewareColor} ${value.tableware}`,
      value.cutlery ? 'including cutlery' : null,
      `placed on a ${value.bgColor} background`,
      'Shot on 70mm lens, Depth of Field, Bokeh, DOF, Tilt Blur, Shutter Speed 1/1000, F/22, White Balance, 32k, Super-Resolution',
      'Editorial photography, photography'
    ].filter(Boolean).join(', ');

    try {
      if (value.aiVersion) {
        const response = await this.imageService.generateImages({ type: this.selectedModel.type, prompt, sampleSize: value.sampleSize, version: value.aiVersion, style: value.style },).toPromise();
        this.selectedImageIndex = -1;
        this.generatedImages = response;
      }
    } catch (err) {
      await this.msgCtrl.showBreakingError(
        {
          header: 'Something went wrong!',
          message: typeof err === 'string' ? err : 'An error occurred. Please retry and if error persists please contact the IT team',
          buttons: [
            {
              text: 'OK',
            },
          ]
        },
      );
    } finally {
      this.loading = false;
    }
  }

  public setFormControlValue(key: string, value: string | boolean): void {
    const control = this.form.controls[key];
    if (control) {
      control.setValue(value);

      if (key === 'aiVersion' && this.selectedModel) {
        Object.entries(this.selectedModel.filters).forEach(([filterKey, values]) => {
          if (!this.form.controls[filterKey] && values[0]) {
            this.setFormControlValue(filterKey, values[0].value);
          }
        });
      }
    } else {
      this.form.addControl(key, new FormControl(value));
    }
  }

  public async clearGeneratedImages(): Promise<void> {
    await this.imageService.clearGenerations();
  }
}
