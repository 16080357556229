import { Component } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { ApticStartup } from '@aptic/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Api, InitializableService, LocalStorageService } from '@aptic/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'paper-plane'
    }
  ];

  constructor(
    protected platform: Platform, protected storage: Storage,
    protected api: Api, protected apticStartup: ApticStartup,
    protected translate: TranslateService, protected alertCtrl: AlertController,
    protected toastCtrl: ToastController, protected router: Router,) {

  }

  protected isApiInitRequestEnabled(): boolean {
    // Return true if you'd like to perform the init request when starting the app, otherwise false
    return false; // TODO: Return true when endpoint is ready
  }

  protected getInitRequestData(): { [p: string]: any } {
    // Add any data that should be sent during the init api request here
    return {};
  }

  protected getLocalStorageServices(): LocalStorageService[] {
    // Add any services that store data in local storage here
    return [];
  }

  protected getServices(): InitializableService[] {
    // Add any service that needs initialization before the application starts here
    return [];
  }

  protected async initEverythingElse(): Promise<void> {
    // Add custom initialization logic here
  }

  protected async loggedIn(): Promise<any> {
    // Custom handler for when the user is logged in
  }

  protected async loggedOut(): Promise<any> {
    // TODO: Find a nicer solution for this.
    // We do this, because there's a lot to initialize for the app
    // when the user logs out. Simply refreshing the page makes sure
    // that everything is correctly initialized.
    window.location.reload();
  }


}
