import { Component } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApticMessageController } from '@aptic/core';
import { catchError, take } from 'rxjs/operators';
import { DropdownOption } from 'src/model/dropdown-option';
import { ApiKeyService } from 'src/model/generation/api-key.service';
import { DescriptionModelConfig } from 'src/model/generation/description/description-model-config';
import { DescriptionModels } from 'src/model/generation/description/description-model-enum';
import { DescriptionModelOptions } from 'src/model/generation/description/description-model-options';
import { DescriptionQuery } from 'src/model/generation/description/description-model-query';
import { DescriptionService } from 'src/model/generation/description/description.service';
import { AIModelPageBase } from '../ai-model-page-base';

@Component({
  selector: 'app-descriptions-page',
  templateUrl: 'descriptions.page.html',
  styleUrls: ['descriptions.page.scss'],
})
export class DescriptionsPage extends AIModelPageBase {

  public models: DescriptionModelConfig[] = [];
  public modelOptions: DescriptionModelOptions[] = [];
  public maxModelVariantSize = 1;

  public loading = false;

  public form = this.fb.group({
    model: ['', Validators.required],
    name: ['', Validators.required],
    description: ['', Validators.required],
    sampleSize: '1',
  });

  public get modelSampleSize(): DropdownOption[] {
    const sampleSize: DropdownOption[] = [];
    if (this.maxModelVariantSize) {
      for (let i = 1; i <= this.maxModelVariantSize; i++) {
        sampleSize.push({ label: (i).toString(), value: i.toString() });
      }
    }
    return sampleSize;
  }

  public alldescriptions$ = this.descriptionService.generations$;
  public responses = [];
  public errorText = '';

  constructor(
    protected readonly fb: UntypedFormBuilder,
    protected readonly descriptionService: DescriptionService,
    protected readonly router: Router,
    protected readonly msgCtrl: ApticMessageController,
    protected readonly apiKeyService: ApiKeyService
  ) {
    super('descriptions-page');
  }

  public async ionViewWillEnter(): Promise<void> {
    await super.ionViewWillEnter();

    if (!this.apiKeyService.hasDescriptionAPIKeys()) {
      await this.msgCtrl.showInterruptingInformation(
        {
          header: 'Missing API Keys',
          message: 'Please fill in the API keys to continue with the product description generation',
          buttons: [
            {
              text: 'Go to settings',
              handler: async () => {
                await this.router.navigateByUrl('/settings');
              }
            },
          ],
          backdropDismiss: false
        },
      );
    }
    this.models = this.descriptionService.getModels();
    if (this.models.length === 0) {
      return;
    }

    const selectedModel = this.models[0]?.value;
    if (selectedModel) {
      this.setFormControlValue('model', selectedModel);
      this.updateModelOptions(selectedModel);
    }

  }

  public get selectedModel(): DescriptionModelConfig | undefined {
    return this.models.find(model => model.value === this.form.value.model);
  }

  public async generateDescription(): Promise<void> {
    if (!this.form.valid) {
      this.errorText = 'Product name and details are required to generate an optimal description.';
      return;
    }
    this.errorText = '';
    const formValues = this.form.value;
    if (formValues) {
      this.loading = true;
      const descriptionGenerator = this.descriptionService.generateDescription(this.form.value.model, formValues as DescriptionQuery);
      if (descriptionGenerator) {
        descriptionGenerator.pipe(
          catchError(err => {
            this.loading = false;
            return this.msgCtrl.showBreakingError(
              {
                header: 'Something went wrong!',
                message: 'An error occurred. Please retry and if error persists please contact the IT team',
                buttons: [
                  {
                    text: 'OK',
                  },
                ]
              },
            );
          }
          )).subscribe(() => {
            this.loading = false;
          });
      }
    }
  }

  public setFormControlValue(key: string, value: string | DescriptionModels | boolean): void {
    const control = this.form.controls[key];
    if (control) {
      control.setValue(value);

      if (key === 'model') {
        this.updateModelOptions(value as DescriptionModels);
      }
    }
  }

  private updateModelOptions(model: DescriptionModels): void {
    this.loading = true;
    this.descriptionService.getModelOptions(model).pipe(take(1)).subscribe(options => {
      this.modelOptions = options;
      options.forEach(option => {
        const control = this.form.controls[option.name];
        if (this.form.value[option.name] && control) {
          control.setValue(option.value);
        } else {
          this.form.addControl(option.name, new FormControl(option.value));
        }
      });
      this.loading = false;
    });

    if (this.selectedModel) {
      this.maxModelVariantSize = this.selectedModel.maxSampleSize;
    }
  }

  public async clearGeneratedDescriptions(): Promise<void> {
    await this.descriptionService.clearGenerations();
  }

}
